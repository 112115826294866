import React from "react";
import { Button, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { GetLoginWithRedirectParams, GetSignUpWithRedirectParams } from "../../Constants/Auth0Constants";
import { RouteConstants } from "../../Constants/RouteConstants";

const MainContainer = styled(Grid)(({ theme }) => ({
  paddingTop: "2vh",
  paddingBottom: "2vh",
  fontFamily: "Roboto",
  fontWeight: 400,
  fontSize: "1.2vw",
  lineHeight: "3vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5vh",
    lineHeight: "2vh",
  },
}));

const TopTextContainer = styled(Grid)({
  padding: ".5em",
});

const OptionsOuterContainer = styled(Grid)({
  paddingTop: "2vh",
});

const PaperInnerContainer = styled(Grid)({
  paddingTop: "2vh",
  paddingBottom: "2vh",
});

const OptionHeader = styled(Grid)(({ theme }) => ({
  fontSize: "1.75vw",
  paddingBottom: ".5vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.75vh",
  },
}));

const OptionContainer = styled(Grid)(({ theme }) => ({
  paddingLeft: "1vw",
  paddingRight: "1vw",
  [theme.breakpoints.down("lg")]: {
    paddingBottom: "1vh",
    paddingTop: "1vh",
  },
}));

const RegistrationIcons = styled(Grid)({
  color: "green",
});

const IndividualSection = styled(Grid)(({ theme }) => ({
  paddingTop: "2vh",
  fontSize: "1vw",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5vh",
  },
}));

const TinyFont = styled(Grid)(({ theme }) => ({
  fontSize: "1vh",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1vh",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5vh",
  },
}));

const NoDecorationLink = styled('a')({
  textDecoration: "none",
  color: "inherit",
  fontFamily: "Roboto",
});

const NewNonProfitWizard: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <MainContainer container justifyContent="center">
      <Grid item xs={12}>
        <Grid container direction="column" justifyContent="flex-start" component={TopTextContainer}>
          <Grid item xs={12}>Our assistance program is designed to support individuals through trusted organizations.</Grid>
          <Grid item xs={12}>We exclusively accept applications from approved social service and non-profit organizations.</Grid>
          <Grid item xs={12}>If your organization fits this criteria, please register to apply for assistance on behalf of those in need.</Grid>
          <Grid item xs={12}>Thank you for partnering with us to make a positive impact.</Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <Grid container alignContent="center" justifyContent="center" component={OptionsOuterContainer}>
          <Paper elevation={3}>
            <Grid item xs={12} component={PaperInnerContainer}>
              <Grid container>
                <Grid item xs={12} md={6} component={OptionContainer}>
                  <Grid container>
                    <OptionHeader item xs={12}>Already Registered?</OptionHeader>
                    <RegistrationIcons item xs={12}><HowToRegIcon style={{fontSize: "3em"}} /></RegistrationIcons>
                    <Grid item xs={12}><Button variant="outlined" onClick={() => loginWithRedirect(GetLoginWithRedirectParams(RouteConstants.PortalRouter))}>Click here to sign in</Button></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} component={OptionContainer}>
                  <Grid container>
                    <OptionHeader item xs={12}>Not Registered?</OptionHeader>
                    <RegistrationIcons item xs={12}><AppRegistrationIcon style={{fontSize: "3em"}} /></RegistrationIcons>
                    <Grid item xs={12}><Button variant="outlined" onClick={() => loginWithRedirect(GetSignUpWithRedirectParams(RouteConstants.PortalRouter))}>Click here to register your non-profit organization</Button></Grid>
                    <TinyFont item xs={12}>*Federal documentation is required to register</TinyFont>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <IndividualSection item xs={12}>If you are an individual in need of assistance, please reach out to our dedicated Community Outreach Coordinator</IndividualSection>
      <IndividualSection item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <Grid container alignItems="center" style={{ lineHeight: '1.5' }}>
              <Grid item style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                <EmailIcon />
              </Grid>
              <Grid item>
                <NoDecorationLink href="mailto:communityoutreach@bethechangegulfcoast.com" style={{ lineHeight: '1.5', marginLeft: '8px' }}>communityoutreach@bethechangegulfcoast.com</NoDecorationLink>
              </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ lineHeight: '1.5' }}>
              <Grid item style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                <CallIcon />
              </Grid>
              {/* <Grid item style={{ marginLeft: '8px' }}>(251) 455-3315</Grid> */}
              <Grid item><a href="tel:+12516807848" style={{textDecoration:"none", color:"inherit"}}>(251) 680-7848</a></Grid>
            </Grid>
          </Grid>
        </Grid>
      </IndividualSection>
    </MainContainer>
  );
};

export default NewNonProfitWizard;